import { client } from "@/lib/commercetools";
import { withAuth } from "@jog/react-components";
import { addBillingAddressBase, addProductBase, addShippingAddressBase, applyGiftcardBase, buckarooCheckoutBase, buckarooGetOrderViewBase, cleanCostBase, createAnonymousCartBase, createLoggedUserCartBase, deleteProductBase, getDutchAddressBase, getGiftcardBase, getPdpBase, getShippingMethodsBase, getUserVouchersBase, getVoucherBase, recalculateCartBase, removeGiftcardBase, removePaymentCostBase, replicateCartBase, replicateOrderBase, setPaymentCostBase, setShippingCostBase, validateCartBase, voucherApplyBase, voucherRemoveBase, } from "@jog/shared";
const apiOption = {
    client,
    setToken: withAuth,
    execute: (request) => client.execute(request).catch((error) => {
        console.log(error);
        throw error;
    }),
};
export const buckarooCheckout = buckarooCheckoutBase(apiOption);
export const buckarooGetOrderView = buckarooGetOrderViewBase(apiOption);
export const replicateCart = replicateCartBase(apiOption);
export const validateCart = validateCartBase(apiOption);
export const replicateOrder = replicateOrderBase(apiOption);
export const getDutchAddress = getDutchAddressBase(apiOption);
export const recalculateCart = recalculateCartBase(apiOption);
export const addProduct = addProductBase(apiOption);
export const deleteProduct = deleteProductBase(apiOption);
export const addBillingAddress = addBillingAddressBase(apiOption);
export const addShippingAddress = addShippingAddressBase(apiOption);
export const createAnonymousCart = createAnonymousCartBase(apiOption);
export const createLoggedUserCart = createLoggedUserCartBase(apiOption);
export const getShippingMethods = getShippingMethodsBase(apiOption);
export const setShippingCost = setShippingCostBase(apiOption);
export const applyGiftcard = applyGiftcardBase(apiOption);
export const removeGiftcard = removeGiftcardBase(apiOption);
export const setPaymentCost = setPaymentCostBase(apiOption);
export const removePaymentCost = removePaymentCostBase(apiOption);
export const getGiftcard = getGiftcardBase(apiOption);
export const getPdp = getPdpBase(apiOption);
export const getVoucher = getVoucherBase(apiOption);
export const getUserVouchers = getUserVouchersBase(apiOption);
export const voucherApply = voucherApplyBase(apiOption);
export const voucherRemove = voucherRemoveBase(apiOption);
export const cleanCost = cleanCostBase(apiOption);
