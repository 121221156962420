export const User = (props) => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <g clipPath="url(#clip0_169_278)">
            <path d="M12 14.75C15.797 14.75 18.875 11.672 18.875 7.875C18.875 4.07804 15.797 1 12 1C8.20304 1 5.125 4.07804 5.125 7.875C5.125 11.672 8.20304 14.75 12 14.75ZM12 14.75C5.92487 14.75 1 18.4437 1 23H23C23 18.4437 18.0751 14.75 12 14.75Z" stroke="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_169_278">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>);
