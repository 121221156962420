import { checkLength, nameValidation, onlyDigitsMaxLength, parsePhoneNumberByCountryCode, passwordValidation, phoneValidation, postCodeValidation, typedBoolean, } from "@jog/shared";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { usePending } from "../components";
import { useI18n } from "./useI18n";
export const useSignUpForm = ({ userStore, email }) => {
    const { $t } = useI18n();
    const { setPending } = usePending();
    const router = useRouter();
    const onSubmit = useCallback(async (values) => {
        try {
            setPending(true);
            await userStore.createNewUser({
                ...values,
                phoneNumber: parsePhoneNumberByCountryCode(values.phoneNumber, values.country),
            });
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (!userStore.isRegistrationError) {
                await router.push(`/login`);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setPending(false);
        }
    }, [router, setPending, userStore]);
    const validate = useCallback((values) => {
        const errors = {};
        if (values.gender === "select") {
            errors.gender = $t("Select a gender");
        }
        if (values.country === "select") {
            errors.country = $t("Select a country");
        }
        if (!nameValidation(values.name)) {
            errors.name = $t("Enter your first name here with first letter capital");
        }
        if (!nameValidation(values.familyName)) {
            errors.familyName = $t("Enter your last name here with first letter capital");
        }
        if (!postCodeValidation(values.postalCode, values.country)) {
            errors.postalCode = $t("Enter a correct zip code");
        }
        if (!onlyDigitsMaxLength(values.houseNumber)) {
            errors.houseNumber = $t("Enter your street number");
        }
        if (!checkLength(values.streetName)) {
            errors.streetName = $t("Enter your street name here");
        }
        if (!checkLength(values.city)) {
            errors.city = $t("Enter your location here");
        }
        if (!phoneValidation(values.phoneNumber, false, [values.country].filter(typedBoolean))) {
            errors.phoneNumber = $t("Enter valid telephone number");
        }
        if (!passwordValidation(values.password)) {
            errors.password = $t("Minimum 6 characters");
        }
        if (!passwordValidation(values.confirmPassword)) {
            errors.confirmPassword = $t("Minimum 6 characters");
        }
        else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = $t("Password does not match");
        }
        return errors;
    }, [$t]);
    const country = process.env.NEXT_PUBLIC_COUNTRY || "";
    const initialValues = useMemo(() => ({
        country: country === "US" ? "select" : country,
        gender: "select",
        name: "",
        insertion: "",
        familyName: "",
        postalCode: "",
        streetName: "",
        houseNumber: "",
        addition: "",
        city: "",
        phoneNumber: "",
        email,
        password: "",
        confirmPassword: "",
    }), [country, email]);
    return {
        onSubmit,
        validate,
        initialValues,
    };
};
