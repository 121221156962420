import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { HeaderScroll } from "@jog/react-components";
import { Logo } from "components";
import { MobileNav } from "./MobileNav";
import { HeaderNav } from "./Nav";
import { UspBar } from "./UspBar";
const CheckoutHeader = () => {
    var _a;
    const layout = useLayout();
    const logo = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo;
    return (<div className="relative h-20 w-full bg-white">
            <div className="relative mx-auto w-full max-w-full lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1400px]">
                <div className="flex h-20 w-full flex-1 items-center justify-center lg:mx-2.5 lg:flex-none lg:justify-start">
                    <Logo src={logo.url} width={logo.dimensions.width} height={logo.dimensions.height} alt="Logo" href="/cart"/>
                </div>
            </div>
        </div>);
};
export const Header = ({ isCartPage, isCheckoutPage }) => {
    if (isCheckoutPage) {
        return (<HeaderScroll>
                <CheckoutHeader />
            </HeaderScroll>);
    }
    return (<HeaderScroll>
            <UspBar />
            <HeaderNav isCartPage={isCartPage}/>
            <MobileNav />
        </HeaderScroll>);
};
