import { NextImage, usePayment } from "@jog/react-components";
import { asImageSrc, asText } from "@prismicio/helpers";
import classNames from "classnames";
export const PaymentRadioTab = ({ children, item, }) => {
    const { setSelectedPaymentMethod, selectedPaymentMethod } = usePayment();
    const isActive = item.buckaroo_service === selectedPaymentMethod;
    return (<div className={classNames("py-6.25 px-2.5 text-sm text-black", isActive ? "border-b border-white bg-grey-checkout" : "border-b border-gray-medium bg-white")}>
            <div>
                <label className="w-full cursor-default" htmlFor={item.buckaroo_service}>
                    <div className="flex flex-wrap items-center gap-x-4">
                        <div className="flex w-full basis-full items-center gap-x-2 px-2 lg:basis-5/12">
                            <div className="flex h-4.5 max-w-[40px] items-center">
                                <input type="radio" id={item.buckaroo_service} name="payment" value={item.buckaroo_service} defaultChecked={isActive} className="w-3.75" onChange={(e) => setSelectedPaymentMethod(e.target.value)}/>
                            </div>
                            <div className="shrink-0 px-2">
                                <div className="flex h-[22px] w-[45px] items-center justify-center bg-white">
                                    <NextImage className="h-full object-contain" blur={false} src={asImageSrc(item.payment_image)} alt={item.buckaroo_service} width={45} height={22}/>
                                </div>
                            </div>
                            <div className="basis-10/12">{asText(item.payment_text)}</div>
                        </div>
                        <div className="-mx-2 grow pr-2.5 text-right">{asText(item.payment_info_text)}</div>
                    </div>
                    {isActive && children}
                </label>
            </div>
        </div>);
};
