import { AddedProductPopup, CartPopup } from "@/components";
import { Basket } from "@/components/Icon/Basket";
import { Favorite } from "@/components/Icon/Favorite";
import { User } from "@/components/Icon/User";
import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { ClientOnly, Link } from "@jog/react-components";
import { lowerCaseLocale } from "@jog/shared";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useMemo, useState } from "react";
const IconNumber = ({ count }) => {
    if (!count)
        return;
    return (<ClientOnly>
            <div className="absolute top-0 -right-1.5 h-4 w-4 rounded-full bg-black text-center text-8 font-medium leading-4 text-white lg:block lg:pt-px lg:text-xs">
                {count}
            </div>
        </ClientOnly>);
};
export const NavInfo = observer(({ isCartPage }) => {
    var _a;
    const [cartShown, setCartShown] = useState(false);
    const wishlistNum = ((_a = useWishlist.use.simpleWishlist()) === null || _a === void 0 ? void 0 : _a.lineItems.length) || 0;
    const { cartStore } = useStores();
    const session = useSession();
    const isUserLoggedIn = useMemo(() => session.status === "authenticated", [session]);
    return (<div className="flex h-full items-center pr-2 lg:pr-0">
            {!isCartPage && (<>
                    <Link className="ml-3 flex h-full items-center p-2" href={isUserLoggedIn ? "/my-account" : "/login"}>
                        <div className="relative flex h-7.5 w-7.5 items-center justify-center pl-px">
                            <User className="text-gray-icon"/>
                        </div>
                    </Link>
                    <Link className="mt-0.75 flex h-full items-center p-2" href={lowerCaseLocale() === "nl-nl" ? "/favorieten" : "/favorite"}>
                        <div className="relative flex h-7.5 w-7.5 items-center justify-center pl-px">
                            <Favorite className="text-gray-icon"/>
                            <IconNumber count={wishlistNum}/>
                        </div>
                    </Link>
                    <div className="relative h-full " onMouseEnter={() => !isCartPage && setCartShown(true)} onMouseLeave={() => setCartShown(false)}>
                        <Link className="flex h-full items-center p-2" href="/cart">
                            <div className="relative flex h-7.5 w-7.5 items-center justify-center">
                                <Basket className="text-gray-icon"/>
                                <IconNumber count={cartStore.cartTotalItems}/>
                            </div>
                        </Link>
                        <CartPopup className="absolute right-0 top-full z-[7] hidden border lg:block" show={cartShown}/>
                    </div>
                    <div className="relative h-full translate-y-[40px] lg:translate-y-0">
                        <AddedProductPopup className="absolute right-0 top-full z-[7] border"/>
                    </div>
                </>)}
        </div>);
});
