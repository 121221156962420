import campaign from "@/lib/prismicio/prismic-campaign.json";
import { useI18n, useNewsletter } from "@jog/react-components";
import classNames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
const Button = ({ children, ...props }) => (<button className={classNames("w-auto font-primary text-xs uppercase", "border-none bg-black text-white", "hover:underline focus:outline-none  disabled:opacity-60", "mt-4 h-[42px] w-full px-12.5 py-3 lg:mt-0 lg:w-auto")} {...props}>
        {children}
    </button>);
export const NewsForm = ({ source, className }) => {
    const { $t } = useI18n();
    const formProps = useNewsletter();
    if (!(campaign === null || campaign === void 0 ? void 0 : campaign.defaultCampaign))
        return <></>;
    return (<Formik initialValues={{ email: "", newsletter: campaign.defaultCampaign, source }} {...formProps}>
            {({ isSubmitting, isValid }) => (<Form className={classNames(className, "relative flex min-w-full flex-col items-baseline lg:min-w-0 lg:flex-1")}>
                    <input type="hidden" name="newsletter"/>
                    <input type="hidden" name="source"/>
                    <div className="flex w-full flex-col lg:flex-row lg:flex-wrap">
                        <Field className="h-[42px] w-full border border-gray-main p-3 text-left font-primary outline-none focus:border focus:border-gray-shuttle disabled:bg-gray-bg lg:w-auto" name="email" type="email" placeholder={$t("E-mail address")} id={`newsletter_email_${source}`}/>
                        <Button disabled={!isValid || isSubmitting}>{$t("Subscribe")}</Button>
                    </div>
                    <ErrorMessage render={(msg) => <div className="mt-2 block w-full text-sm">{msg}</div>} name="email"/>
                </Form>)}
        </Formik>);
};
