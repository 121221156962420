import { NextImage, RichTextBase } from "@jog/react-components";
import { replacePlaceholderInUrl } from "@jog/shared";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
export const GRichText = RichTextBase({ paragraphDefaultCss: "text-sm" });
export const RichText = ({ field }) => (<PrismicRichText field={field} components={{
        heading1: ({ children }) => (<h1 className="text-[65px] font-medium leading-tight text-black">{children}</h1>),
        heading2: ({ children }) => (<h2 className="text-[32px] font-medium leading-tight text-black">{children}</h2>),
        heading3: ({ children }) => (<h3 className="text-[28px] font-medium leading-tight text-black">{children}</h3>),
        heading4: ({ children }) => <h4 className="text-[24px] leading-tight text-black">{children}</h4>,
        heading5: ({ children }) => <h5 className="text-[20px] leading-tight text-black">{children}</h5>,
        heading6: ({ children }) => <h6 className="text-base leading-tight text-black">{children}</h6>,
        paragraph: ({ children, node, text }) => {
            if (!node.spans.length && !text) {
                return (<p>
                            <br />
                        </p>);
            }
            return <p className="text-xs font-normal leading-tight text-black">{children}</p>;
        },
        list: ({ children }) => (<ul className="list-disc pl-10 text-xs font-normal leading-tight text-black">{children}</ul>),
        oList: ({ children }) => (<ol className="list-decimal pl-10 text-xs font-normal leading-tight text-black">{children}</ol>),
        hyperlink: ({ node, children }) => {
            if (node.data.url) {
                node.data.url = replacePlaceholderInUrl(node.data.url).replace(process.env.NEXT_PUBLIC_SITE_URL || "", "");
                node.data.url = node.data.url.replace(/https:\/\/tel*:/, "tel:");
                node.data.url = node.data.url.replace(/https:\/\/mailto:/, "mailto:");
            }
            const target = node.data.link_type === "Web" && node.data.target ? node.data.target : "_self";
            return (<PrismicLink field={node.data} target={target} className="text-black underline">
                        {children}
                    </PrismicLink>);
        },
        image: ({ node }) => (<p className="overflow-x-auto">
                    <NextImage className="max-w-none" width={node.dimensions.width} height={node.dimensions.height} src={node.url} alt={node.alt || ""}/>
                </p>),
    }}/>);
