import { Price } from "@/components/Price/Price";
import { Link, NextImage, getProductPrice, getProductUrl, useI18n, useNavSearch } from "@jog/react-components";
import { Input } from "components";
import { observer } from "mobx-react";
const SearchItem = ({ item }) => {
    const { firstPrice, hasDiscount } = getProductPrice(item);
    const pdpUrl = getProductUrl(item);
    return (<Link href={pdpUrl}>
            <div className="flex items-center px-3 py-5 text-sm hover:bg-gray-bg">
                <div className="p-2">
                    <NextImage blur={false} className="my-0 mx-auto block" height={80} width={54} src={item.image} alt={item.title}/>
                </div>
                <div className="h-25 p-2">
                    <div>{item.title}</div>
                    <div className="mt-1.5">
                        {hasDiscount && Number(firstPrice) > Number(item.price) ? (<div className="flex flex-col gap-2">
                                <Price color="#444a55" fontSize="13px" className="font-normal" isOldPrice isListerPrice price={firstPrice}/>
                                <Price color="#ff0000" fontSize="18px" isListerPrice price={item.price}/>
                            </div>) : firstPrice && Number(firstPrice) > Number(item.price) ? (<div className="flex flex-col gap-2">
                                <Price color="#444a55" fontSize="13px" isOldPrice isListerPrice className="font-normal" price={firstPrice}/>
                                <Price color="#ff0000" fontSize="18px" isListerPrice price={item.price}/>
                            </div>) : (<Price price={item.price} fontSize="18px" isListerPrice/>)}
                    </div>
                </div>
            </div>
        </Link>);
};
const SearchIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_155_254)">
            <path d="M22.6464 23.3536C22.8417 23.5488 23.1583 23.5488 23.3536 23.3536C23.5488 23.1583 23.5488 22.8417 23.3536 22.6465L22.6464 23.3536ZM18.6176 10.0588C18.6176 14.7857 14.7857 18.6176 10.0588 18.6176V19.6176C15.338 19.6176 19.6176 15.338 19.6176 10.0588H18.6176ZM10.0588 18.6176C5.33192 18.6176 1.5 14.7857 1.5 10.0588H0.5C0.5 15.338 4.77963 19.6176 10.0588 19.6176V18.6176ZM1.5 10.0588C1.5 5.33192 5.33192 1.5 10.0588 1.5V0.5C4.77963 0.5 0.5 4.77963 0.5 10.0588H1.5ZM10.0588 1.5C14.7857 1.5 18.6176 5.33192 18.6176 10.0588H19.6176C19.6176 4.77963 15.338 0.5 10.0588 0.5V1.5ZM16.1759 16.883L22.6464 23.3536L23.3536 22.6465L16.883 16.1759L16.1759 16.883Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath>
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>);
export const NavSearch = observer(() => {
    const { $t } = useI18n();
    const { handleSearch, onBlur, onFocus, suggestions, items, isSearchShown, searchTerm, setSearchTerm } = useNavSearch({ itemLength: 4, suggestionLength: 6 });
    return (<>
            <form className="relative w-full" onSubmit={async (e) => {
            e.preventDefault();
            await handleSearch();
        }}>
                <div onClick={() => handleSearch(searchTerm)} className="absolute top-2 right-2 flex h-6 w-6 cursor-pointer items-center justify-center pl-1 lg:top-1 lg:right-0">
                    <SearchIcon />
                </div>
                <Input type="text" placeholder={$t("Search")} className="w-full pr-6 text-left text-xs uppercase lg:border-transparent lg:!border-b-black lg:pl-0 lg:!text-left lg:focus:border-transparent" required onChange={(e) => {
            setSearchTerm(e.target.value);
        }} onBlur={onBlur} onFocus={onFocus}/>
            </form>
            {(suggestions.length > 0 || items.length > 0) && isSearchShown && (<div className="absolute right-0 top-full w-full overflow-x-hidden border border-gray-medium bg-white lg:top-[62px] lg:w-[400px]">
                    {suggestions.length > 0 && (<div className="border-b border-gray-medium">
                            {suggestions.map((item, i) => (<div className="cursor-pointer p-3 text-sm text-black no-underline hover:bg-gray-bg" key={i} onClick={() => handleSearch(item.title)}>
                                    {item.title}
                                </div>))}
                        </div>)}
                    {items.map((item) => (<SearchItem item={item} key={item.itemno}/>))}
                </div>)}
        </>);
});
