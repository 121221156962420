import { getUserVouchers, getVoucher, voucherApply, voucherRemove } from "@/lib/jog";
import { VoucherStore } from "@jog/react-components";
import cartStore from "./cartStore";
const voucherStore = new VoucherStore({
    api: {
        getVoucher,
        getUserVouchers,
        voucherApply,
        voucherRemove,
    },
    prismic: {},
    store: {
        cartStore,
    },
    skipVoucherCheck: true,
});
export default voucherStore;
