import { Container, Logo } from "@/components";
import { Burger } from "@/components/Icon/Burger";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { Link, MenuPopup, NextImage, useSidebar } from "@jog/react-components";
import classNames from "classnames";
import { useState } from "react";
import { NavInfo } from "./NavInfo";
import { NavSearch } from "./NavSearch";
const RichContent = ({ htmlStr }) => (<div className="mb-0" dangerouslySetInnerHTML={{ __html: htmlStr }}/>);
const CategoryTag = ({ label, color, bgColor }) => (<span className="ml-2 flex items-center justify-center bg-red-500 px-1 text-center !font-sans !text-[0.75em] text-white" style={{ color, backgroundColor: bgColor }}>
        {label}
    </span>);
const DesktopNavMenu = () => {
    var _a, _b;
    const [activeMenu, setActiveMenu] = useState("");
    const layout = useLayout();
    return (<div className="flex text-13 text-gray-base">
            {(_b = (_a = layout === null || layout === void 0 ? void 0 : layout.nav) === null || _a === void 0 ? void 0 : _a.desktop) === null || _b === void 0 ? void 0 : _b.map((navItem) => {
            var _a;
            return (<div key={navItem.id} onMouseEnter={() => setActiveMenu(navItem.id)} onMouseLeave={() => setActiveMenu("")}>
                    {((_a = navItem.categoryName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== "winkels" && (<Link className="block cursor-pointer px-4 py-7.5 uppercase text-gray-base no-underline" to={navItem.categoryUrl}>
                            <div className="relative hover:underline hover:decoration-2" style={{ color: navItem.categoryTextColor }}>
                                {activeMenu === navItem.id && !!navItem.subCategoryItems.length && (<span className="absolute left-[40%] -bottom-[39px] z-20 h-4 w-4 rotate-45 border border-b-0 border-r-0 border-gray-light bg-white"/>)}
                                <RichContent htmlStr={navItem.categoryName}/>
                            </div>
                        </Link>)}
                    <MenuPopup isOpen={activeMenu === navItem.id && !!navItem.subCategoryItems.length}>
                        <Container className="flex p-5">
                            {navItem.subCategoryItems.map((subCategoryItem, index) => (<div className="flex-1" key={index}>
                                    <Link onClick={() => setActiveMenu("")} to={subCategoryItem.subCategoryUrl}>
                                        <div className="flex items-center !leading-5">
                                            <div className={classNames("font-bold", subCategoryItem.subCategoryUrl
                        ? "cursor-pointer hover:underline"
                        : "cursor-default")} style={{ color: subCategoryItem.subCategoryTextColor }}>
                                                <RichContent htmlStr={subCategoryItem.subCategoryName}/>
                                            </div>
                                            {!!subCategoryItem.subLabelText && (<CategoryTag label={subCategoryItem.subLabelText} color={subCategoryItem.subLabelTextColor} bgColor={subCategoryItem.subLabelBgColor}/>)}
                                        </div>
                                    </Link>
                                    <div className="mt-2.5">
                                        {!!subCategoryItem.thirdCategoryItems.length &&
                        subCategoryItem.thirdCategoryItems.map((thirdCategoryItem, index) => (<div className="w-full py-1.5" key={index}>
                                                    <Link onClick={() => setActiveMenu("")} to={thirdCategoryItem.thirdCategoryUrl}>
                                                        <div className="flex items-center">
                                                            <div className="text-13 leading-5 hover:underline" style={{
                                color: thirdCategoryItem.thirdCategoryTextColor,
                            }}>
                                                                <RichContent htmlStr={thirdCategoryItem.thirdCategoryName}/>
                                                            </div>
                                                            {!!thirdCategoryItem.thirdLabelText && (<CategoryTag label={thirdCategoryItem.thirdLabelText} color={thirdCategoryItem.thirdLabelTextColor} bgColor={thirdCategoryItem.thirdLabelBgColor}/>)}
                                                        </div>
                                                    </Link>
                                                </div>))}
                                    </div>
                                </div>))}
                            {(navItem === null || navItem === void 0 ? void 0 : navItem.bannerImage) && (<div className="flex-1">
                                    <Link onClick={() => setActiveMenu("")} to={navItem.bannerUrl}>
                                        <NextImage width={300} height={300} quality={100} src={navItem.bannerImage} alt="Category navigation banner"/>
                                    </Link>
                                </div>)}
                        </Container>
                    </MenuPopup>
                </div>);
        })}
        </div>);
};
export const HeaderNav = ({ isCartPage }) => {
    var _a;
    const { toggle } = useSidebar();
    const layout = useLayout();
    const logo = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.logo;
    return (<div className="w-full bg-white">
            <div className="mx-auto w-full max-w-full lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1400px]">
                <div className="grid grid-cols-[auto,1fr,auto,auto] grid-rows-[60px,40px] lg:grid-rows-[80px]">
                    <div className="flex items-center justify-center lg:hidden">
                        <button className="mx-2 p-2" onClick={() => toggle()}>
                            <Burger className="text-gray-icon"/>
                        </button>
                    </div>
                    <div className="lg:w-31.5 col-start-2 w-[125px] lg:col-start-1">
                        <Logo src={logo.url} width={logo.dimensions.width} height={logo.dimensions.height} alt="Logo"/>
                    </div>
                    <div className="ml-4 hidden lg:block">
                        <DesktopNavMenu />
                    </div>
                    <div className="relative col-span-full col-start-1 flex h-full items-center justify-center lg:col-span-1 lg:col-start-3">
                        <NavSearch />
                    </div>
                    <div className="col-start-3 row-start-1 flex h-full items-center justify-center lg:col-start-4">
                        <NavInfo isCartPage={isCartPage}/>
                    </div>
                </div>
            </div>
        </div>);
};
