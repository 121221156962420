import { prismicClient, retry, transDesktopNav, transLayout, transMobileNav, transTranslations, } from "@jog/shared";
import { isFilled } from "@prismicio/helpers";
export const desktopMainNavQuery = `
    {
        desktop_main_nav {
            display_name
            nav_items {
                nav_item {
                    ... on desktop_nav {
                        ...desktop_navFields
                    }
                }
            }
        }
    }
`;
export const mobileMainNavQuery = `
    {
        mobile_main_nav {
            display_name
            logo
            nav_items {
                nav_item {
                    ... on mobile_nav {
                        ...mobile_navFields
                    }
                }
            }
        }
    }
`;
export const layout = async () => {
    const client = prismicClient({});
    const layout = await retry(() => client.getSingle("layout").then((res) => transLayout(res.data)));
    const [desktop, mobile] = await Promise.all([
        retry(() => client
            .getSingle("desktop_main_nav", { graphQuery: desktopMainNavQuery })
            .then((res) => [...res.data.nav_items]
            .map((item) => isFilled.contentRelationship(item.nav_item) && transDesktopNav(item.nav_item))
            .filter(Boolean))),
        retry(() => client
            .getSingle("mobile_main_nav", { graphQuery: mobileMainNavQuery })
            .then((res) => [...res.data.nav_items]
            .map((item) => isFilled.contentRelationship(item.nav_item) && transMobileNav(item.nav_item))
            .filter(Boolean))),
    ]);
    const translations = await retry(() => client.getSingle("translations").then((res) => transTranslations(res.data.translations)));
    return { layout, nav: { mobile, desktop }, translations };
};
