import { useTextConversion } from "@/lib/prismicio/useTextConversion";
import { BreadcrumbListHead, Container, Link } from "@jog/react-components";
import classNames from "classnames";
const BreadcrumbItem = ({ text, href }) => {
    const title = useTextConversion(text);
    return (<div className="group flex items-center">
            <div className="w-8 text-center group-first-of-type:hidden">{">"}</div>
            <Link className="uppercase text-black hover:text-gray-sand group-last-of-type:pointer-events-none group-last-of-type:text-gray-sand" to={href} title={title}>
                {title}
            </Link>
        </div>);
};
const home = { text: "Home", href: "/" };
export const Breadcrumbs = ({ displayInMobile, list, }) => {
    return (<>
            <BreadcrumbListHead list={[home, ...list]}/>
            <Container className={classNames("lg:block", displayInMobile ? "block" : "hidden")}>
                <div className="mx-2 my-4 flex text-xs lg:my-5 lg:mx-0">
                    {[home, ...list].map(({ text, href }) => (<BreadcrumbItem href={href} text={text.replace(/-/g, " ")} key={href}/>))}
                </div>
            </Container>
        </>);
};
