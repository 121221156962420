import { FormikSelectFieldBase } from "@jog/react-components";
import React from "react";
export const FormikSelectField = React.memo(FormikSelectFieldBase({
    wrapperCss: {
        ".select-wrapper": {
            position: "relative",
            "&:before": {
                top: "50%",
                right: "12px",
                fontSize: "23px",
                transform: "translateY(-50%)",
                color: "#000",
            },
        },
    },
}));
