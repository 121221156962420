import { addBillingAddress, addProduct, addShippingAddress, applyGiftcard, cleanCost, createAnonymousCart, createLoggedUserCart, deleteProduct, getShippingMethods, recalculateCart, removeGiftcard, removePaymentCost, replicateCart, setPaymentCost, setShippingCost, validateCart, } from "@/lib/jog";
import { CartStore } from "@jog/react-components";
import userStore from "./userStore";
const cartStore = new CartStore({
    api: {
        createAnonymousCart,
        createLoggedUserCart,
        recalculateCart,
        addProduct,
        deleteProduct,
        addBillingAddress,
        addShippingAddress,
        getShippingMethods,
        setShippingCost,
        applyGiftcard,
        removeGiftcard,
        replicateCart,
        setPaymentCost,
        removePaymentCost,
        validateCart,
        cleanCost,
    },
    store: { userStore },
});
export default cartStore;
