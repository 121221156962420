import { RadioTabBase, RadioTabWrapperBase } from "@jog/react-components";
import { styled } from "@jog/theming";
import React from "react";
const RadioTabWrapper = styled(RadioTabWrapperBase) `
    margin-bottom: 30px;
    padding-bottom: 30px;
    color: #000;
    font-size: 14px;
    border-bottom: 1px solid #d0d2d4;
`;
export const RadioTab = React.memo(RadioTabBase({
    RadioTabWrapper,
    tabHeaderWrapperCss: {
        ".tab-head__right-wrapper": {
            color: "#000",
            ".location-icon::before": {
                fontSize: "14px",
            },
        },
        ".jc-green": {
            color: "#000",
        },
    },
    distanceLabelColor: "#000",
}));
