import { GRichText } from "@/components/RichText/RichText";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { ClientOnly, Container, Countdown } from "@jog/react-components";
import { getBaseCategoryFromPrettyPath } from "@jog/shared";
import { asDate } from "@prismicio/helpers";
import dayjs from "dayjs";
import { useRouter } from "next/router";
export const TopBanner = () => {
    var _a, _b, _c, _d;
    const router = useRouter();
    const layout = useLayout();
    const countdown = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.countdown;
    if (!(countdown === null || countdown === void 0 ? void 0 : countdown.display))
        return null;
    const pageUrl = ((_c = (_b = router.asPath) === null || _b === void 0 ? void 0 : _b.split("?")) === null || _c === void 0 ? void 0 : _c[0]) || "";
    const asPath = (_d = getBaseCategoryFromPrettyPath(pageUrl)) === null || _d === void 0 ? void 0 : _d.join("/");
    const hiddenTopBanner = countdown.excludePageGroup.includes(asPath);
    if (hiddenTopBanner || !countdown.items.length)
        return null;
    return (<div style={{ backgroundColor: countdown.background }}>
            <Container>
                <ClientOnly>
                    <div className="p-2.5 text-center" style={{ color: countdown.color, fontSize: countdown.fontSize }}>
                        {countdown.items.map((item, index) => {
            const currentDate = dayjs().toDate();
            const startDate = asDate(item.start);
            const endDate = asDate(item.end);
            const countdownBanner = startDate < currentDate && endDate > currentDate && item.countdown;
            const textBanner = startDate < currentDate && endDate > currentDate;
            return (<div key={index}>
                                    {countdownBanner && (<p className="mb-0 inline-block pr-1.25">
                                            Nog <Countdown end={asDate(item.end)}/>
                                        </p>)}
                                    {textBanner && (<GRichText linkClass="text-inherit underline hover:no-underline" paragraphClass="inline-block mb-0 text-sm" field={item.text}/>)}
                                </div>);
        })}
                    </div>
                </ClientOnly>
            </Container>
        </div>);
};
