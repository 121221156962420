import { navigate } from "@jog/shared";
import { Container, WhiteBox } from "components";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useStores } from "stores";
import { SignUpForm } from "./SignUpForm";
export const SignUp = observer(() => {
    const { userStore } = useStores();
    const router = useRouter();
    useEffect(() => {
        userStore.clearRegistrationError();
    }, [userStore]);
    useEffect(() => {
        if (router.isReady) {
            const email = new URLSearchParams(window.location.search).get("email");
            if (email) {
                userStore.unregisteredEmail = email;
            }
            else {
                if (!userStore.unregisteredEmail) {
                    navigate(`/login`);
                }
            }
        }
    }, [router.isReady, userStore]);
    return (<Container>
            <WhiteBox>
                <div className="w-full lg:w-10/12">
                    {userStore.unregisteredEmail ? <SignUpForm email={userStore.unregisteredEmail}/> : <></>}
                </div>
            </WhiteBox>
        </Container>);
});
