import { useCallback } from "react";
import { usePending } from "../components";
export const useCheckDutchAddress = ({ getDutchAddress, }) => {
    const { setPending } = usePending();
    const getAddress = useCallback(async (values, errors, setFieldValue) => {
        var _a;
        if (((_a = values.country) === null || _a === void 0 ? void 0 : _a.toUpperCase()) !== "NL")
            return;
        if (errors.postalCode)
            return;
        if (errors.houseNumber)
            return;
        const { postalCode, houseNumber, addition } = values;
        setPending(true);
        try {
            const res = await getDutchAddress(postalCode || "", houseNumber || "", addition || "");
            if (!res)
                return;
            setFieldValue("city", res.body.city || "");
            setFieldValue("streetName", res.body.streetname || "");
        }
        catch {
        }
        finally {
            setPending(false);
        }
    }, [getDutchAddress, setPending]);
    return { getAddress };
};
